import React from 'react';
import ImageBlock from '../ImageBlock/ImageBlock';

import './ContentBlock.css';

const ContentBlock = (props) => {
    var ret_content_block;
    var directionStyle;
    var content;
    var content_block = 'content-block';
    if (props.animate) {
        content_block += ' show';
    }
    if (props.direction === 'left') {
        directionStyle = {
            justifyContent: 'flex-start',
        };
        content = (
            <div
                className={content_block}
                style={props.style}
                ref={props.passRef}
            >
                <ImageBlock
                    image={props.image}
                    alt={props.alt}
                    direction={props.direction}
                    imageStyle={props.imageStyle}
                />
                <div className='content-text-image' style={props.textStyle}>
                    {props.text}
                </div>
            </div>
        );
    } else if (props.direction === 'right') {
        directionStyle = {
            justifyContent: 'flex-end',
        };
        content = (
            <div className={content_block} style={props.style}>
                <div className='content-text-image' style={props.textStyle}>
                    {props.text}
                </div>
                <ImageBlock
                    image={props.image}
                    alt={props.alt}
                    direction={props.direction}
                    imageStyle={props.imageStyle}
                />
            </div>
        );
    } else {
        directionStyle = {
            justifyContent: 'center',
            flexDirection: 'column',
        };
        content = (
            <div className={content_block} style={props.style}>
                <ImageBlock
                    image={props.image}
                    alt={props.alt}
                    direction={props.direction}
                    imageStyle={props.imageStyle}
                />
                <div className='content-text-image' style={props.textStyle}>
                    {props.text}
                </div>
            </div>
        );
    }

    if (props.image) {
        ret_content_block = content;
    } else {
        ret_content_block = (
            <div
                className={content_block}
                style={{ ...directionStyle, ...props.style }}
            >
                <div className='content-notext' style={props.textStyle}>
                    {props.text}
                </div>
            </div>
        );
    }
    return ret_content_block;
};

ContentBlock.defaultProps = {
    text: 'n/a',
};

export default ContentBlock;
