import React from 'react';

import './PlaceHolder.css';

const PlaceHolder = (props) => {
    return (
        <div className='place-holder'>
            <svg
                width={props.width}
                height={props.height}
                viewBox={`0 0 ${props.width} ${props.height}`}
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <rect
                    width={props.width}
                    height={props.height}
                    rx='10'
                    ry='10'
                    fill='#CCC'
                />
            </svg>
        </div>
    );
};
// width='300px'
//                             height='170px'
PlaceHolder.defaultProps = {
    width: '300',
    height: '170',
};
export default PlaceHolder;
