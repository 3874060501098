import React, { useLayoutEffect, useState, useRef, useEffect } from 'react';
import ContentBlock from './ContentBlock';

import add_company_img from '../../img/projects/inventoryApp/addCompany.gif';
import delete_company_img from '../../img/projects/inventoryApp/deleteCompany.gif';
import rename_company_img from '../../img/projects/inventoryApp/renameCompany.gif';
import add_model_img from '../../img/projects/inventoryApp/addModel.gif';
import multi_delete_img from '../../img/projects/inventoryApp/multiDelete.gif';
import take_photo_img from '../../img/projects/inventoryApp/takePhoto.gif';

import TextBlock from '../TextBlock/TextBlock';
import './ProjectPage.css';

const InventoryAppPage = (props) => {
    const [show, setShow] = useState({
        blockOne: 'sliding-block',
        blockTwo: 'sliding-block',
        blockThree: 'sliding-block',
        blockFour: 'sliding-block',
        blockFive: 'sliding-block',
        blockSix: 'sliding-block',
    });
    const [windowWidth, setWindowWidth] = useState(800);
    const ref1 = useRef(null),
        ref2 = useRef(null),
        ref3 = useRef(null),
        ref4 = useRef(null),
        ref5 = useRef(null),
        ref6 = useRef(null),
        pageRef = useRef(null);

    useEffect(() => {
        setWindowWidth(window.outerWidth);
    }, []);

    useLayoutEffect(() => {
        const topPos = (element) => element.getBoundingClientRect().top;
        //added to reduce redundancy
        const div1Pos = topPos(ref1.current),
            div2Pos = topPos(ref2.current),
            div3Pos = topPos(ref3.current),
            div4Pos = topPos(ref4.current),
            div5Pos = topPos(ref5.current),
            div6Pos = topPos(ref6.current);

        const onScroll = () => {
            const scrollPos = window.scrollY + window.innerHeight;

            if (div1Pos < scrollPos && !(div2Pos < scrollPos)) {
                setShow((state) => ({
                    ...state,
                    blockOne: 'sliding-block show',
                }));
            } else if (div2Pos < scrollPos) {
                setShow((state) => ({
                    ...state,
                    blockOne: 'sliding-block hide',
                }));
            } else {
                setShow((state) => ({
                    ...state,
                    blockOne: 'sliding-block',
                }));
            }
            if (div2Pos < scrollPos && !(div3Pos < scrollPos)) {
                setShow((state) => ({
                    ...state,
                    blockTwo: 'sliding-block show',
                }));
            } else if (div2Pos < scrollPos) {
                setShow((state) => ({
                    ...state,
                    blockTwo: 'sliding-block hide',
                }));
            } else {
                setShow((state) => ({
                    ...state,
                    blockTwo: 'sliding-block',
                }));
            }
            if (div3Pos < scrollPos && !(div4Pos < scrollPos)) {
                setShow((state) => ({
                    ...state,
                    blockThree: 'sliding-block show',
                }));
            } else if (div3Pos < scrollPos) {
                setShow((state) => ({
                    ...state,
                    blockThree: 'sliding-block hide',
                }));
            } else {
                setShow((state) => ({
                    ...state,
                    blockThree: 'sliding-block',
                }));
            }
            if (div4Pos < scrollPos && !(div5Pos < scrollPos)) {
                setShow((state) => ({
                    ...state,
                    blockFour: 'sliding-block show',
                }));
            } else if (div4Pos < scrollPos) {
                setShow((state) => ({
                    ...state,
                    blockFour: 'sliding-block hide',
                }));
            } else {
                setShow((state) => ({
                    ...state,
                    blockFour: 'sliding-block',
                }));
            }
            if (div5Pos < scrollPos && !(div6Pos < scrollPos)) {
                setShow((state) => ({
                    ...state,
                    blockFive: 'sliding-block show',
                }));
            } else if (div5Pos < scrollPos) {
                setShow((state) => ({
                    ...state,
                    blockFive: 'sliding-block hide',
                }));
            } else {
                setShow((state) => ({
                    ...state,
                    blockFive: 'sliding-block',
                }));
            }
            if (
                div6Pos < scrollPos &&
                !(
                    div6Pos + ref1.current.getBoundingClientRect().height <
                    scrollPos
                )
            ) {
                setShow((state) => ({
                    ...state,
                    blockSix: 'sliding-block show',
                }));
            } else if (div6Pos < scrollPos) {
                setShow((state) => ({
                    ...state,
                    blockSix: 'sliding-block hide',
                }));
            } else {
                setShow((state) => ({
                    ...state,
                    blockSix: 'sliding-block',
                }));
            }
        };

        const onResizeWindow = () => {
            setWindowWidth(window.outerWidth);
        };

        window.addEventListener('scroll', onScroll);
        window.addEventListener('resize', onResizeWindow);
        return () => {
            window.removeEventListener('scroll', onScroll);
            window.removeEventListener('resize', onResizeWindow);
        };
    }, []);
    var text1 =
        'A simple modal page pops up with a sliding animation when the plus sign is pressed on the top right. When submit is pressed the data will be send to the backend server and uploaded to the database';
    var text2 =
        'Deleting a company is done by swiping left on a company entry and clicking the delete button. An alert is popped up to confirm the action to prevent mis-action';
    var text3 =
        'Renaming a company is done easily in the same manner as deleting a company. An update packet is sent to update the data in the database';
    var text4 =
        'Adding a model to the corresponding company is also done in a similar way.';
    var text5 =
        'Holding a model in the model screen will trigger multi select mode, which allows the users to delete multiple models simultaneously.';
    var text6 =
        'User has option to take a picture or choose from library for the specific model.';

    var font_size = windowWidth > 600 ? '30px' : '20px';
    return (
        <div className='project-page' ref={pageRef}>
            <h1 className='title'>
                Inventory App written in React Native (WIP)
            </h1>
            <TextBlock text='The app was written to serve as a inventory record app that records different items from different companies. It is still work-in-progress and has been tested on both Android and iOS simulators.' />
            <div className='container'>
                <div className={show.blockOne} ref={ref1}>
                    <ContentBlock
                        style={{
                            backgroundColor: 'rgba(74, 74, 87,0.2)',
                            marginTop: '30px',
                            marginBottom: '30px',
                            borderRadius: '5%',
                        }}
                        image={add_company_img}
                        imageStyle={{
                            maxWidth: '100%',
                            width: '300px',
                            height: 'auto',
                        }}
                        textStyle={{ fontSize: font_size, width: '100%' }}
                        text={text1}
                        direction='left'
                    />
                </div>
                <div className={show.blockTwo} ref={ref2}>
                    <ContentBlock
                        style={{
                            backgroundColor: 'rgba(74, 74, 87,0.2)',
                            marginTop: '30px',
                            marginBottom: '30px',
                            borderRadius: '5%',
                        }}
                        image={delete_company_img}
                        imageStyle={{
                            maxWidth: '100%',
                            width: '300px',
                            height: 'auto',
                        }}
                        textStyle={{ fontSize: font_size, width: '100%' }}
                        text={text2}
                        direction='left'
                    />
                </div>
                <div className={show.blockThree} ref={ref3}>
                    <ContentBlock
                        style={{
                            backgroundColor: 'rgba(74, 74, 87,0.2)',
                            marginTop: '30px',
                            marginBottom: '30px',
                            borderRadius: '5%',
                        }}
                        image={rename_company_img}
                        imageStyle={{
                            maxWidth: '100%',
                            width: '300px',
                            height: 'auto',
                        }}
                        textStyle={{ fontSize: font_size, width: '100%' }}
                        text={text3}
                        direction='left'
                    />
                </div>
                <div className={show.blockFour} ref={ref4}>
                    <ContentBlock
                        style={{
                            backgroundColor: 'rgba(74, 74, 87,0.2)',
                            marginTop: '30px',
                            marginBottom: '30px',
                            borderRadius: '5%',
                        }}
                        image={add_model_img}
                        imageStyle={{
                            maxWidth: '100%',
                            width: '300px',
                            height: 'auto',
                        }}
                        textStyle={{ fontSize: font_size, width: '100%' }}
                        text={text4}
                        direction='left'
                    />
                </div>
                <div className={show.blockFive} ref={ref5}>
                    <ContentBlock
                        style={{
                            backgroundColor: 'rgba(74, 74, 87,0.2)',
                            marginTop: '30px',
                            marginBottom: '30px',
                            borderRadius: '5%',
                        }}
                        image={multi_delete_img}
                        imageStyle={{
                            maxWidth: '100%',
                            width: '300px',
                            height: 'auto',
                        }}
                        textStyle={{ fontSize: font_size, width: '100%' }}
                        text={text5}
                        direction='left'
                    />
                </div>
                <div className={show.blockSix} ref={ref6}>
                    <ContentBlock
                        style={{
                            backgroundColor: 'rgba(74, 74, 87,0.2)',
                            marginTop: '30px',
                            marginBottom: '30px',
                            borderRadius: '5%',
                        }}
                        image={take_photo_img}
                        imageStyle={{
                            maxWidth: '100%',
                            width: '300px',
                            height: 'auto',
                        }}
                        textStyle={{ fontSize: font_size, width: '100%' }}
                        text={text6}
                        direction='left'
                    />
                </div>
            </div>
            <TextBlock
                textStyle={{ fontSize: '20px', marginBottom: '400px' }}
                text='The app is still working in progress and updates will be made.'
            />
        </div>
    );
};

export default InventoryAppPage;
