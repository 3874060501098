import React, { Component } from 'react';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import ToolBar from './components/ToolBar/ToolBar.js';
import SideBar from './components/SideBar/SideBar.js';
import Backdrop from './components/Backdrop/Backdrop.js';
import Home from './components/Home/Home.js';
import Footer from './components/Footer/Footer.js';
import About from './components/About/About';
import Projects from './components/Projects/Projects';
import PersonalProjects from './components/Projects/PersonalProjects';
import CourseProjects from './components/Projects/CourseProjects';
import HDRPage from './components/ProjectPage/HDRPage';
import InventoryAppPage from './components/ProjectPage/InventoryAppPage';
import RoboEduPage from './components/ProjectPage/RoboPage';
import TankGame from './components/TankGame/TankGame';
import Contact from './components/Contact/Contact';

class App extends Component {
    state = {
        sideBarOpen: false,
    };

    ToggleSideBarHandler = () => {
        this.setState((prevState) => {
            return { sideBarOpen: !prevState.sideBarOpen };
        });
    };

    BackdropClickHandler = () => {
        this.setState({ sideBarOpen: false });
    };

    // LightSwitchHandler = () => {
    //     console.log('switching lights');
    //     this.setState((prevState) => {
    //         return { lightOn: !prevState.lightOn };
    //     });
    // };

    render() {
        var backdrop;
        if (this.state.sideBarOpen) {
            backdrop = <Backdrop clickHandler={this.BackdropClickHandler} />;
        }
        return (
            <Router>
                <div className='app-body'>
                    <ToolBar toggleSideBarHandler={this.ToggleSideBarHandler} />
                    <SideBar
                        show={this.state.sideBarOpen}
                        toggleSideBarHandler={this.ToggleSideBarHandler}
                    />
                    {backdrop}
                    <main className='main'>
                        <Switch>
                            <Route path='/' exact component={Home} />
                            <Route path='/portfolio' component={Home} />
                            <Route path='/about' component={About} />
                            <Route
                                path='/experience'
                                exact
                                component={Projects}
                            />
                            <Route
                                path='/experience/hdr'
                                component={HDRPage}
                            ></Route>
                            <Route
                                path='/experience/inventory-app'
                                component={InventoryAppPage}
                            ></Route>
                            <Route
                                path='/experience/personal'
                                component={PersonalProjects}
                            />
                            <Route
                                path='/experience/course'
                                component={CourseProjects}
                            />
                            <Route
                                path='/experience/roboedu'
                                component={RoboEduPage}
                            />
                            <Route path='/tankgame' component={TankGame} />
                            <Route path='/contact' component={Contact} />
                        </Switch>
                    </main>
                    <Footer />
                </div>
            </Router>
        );
    }
}

export default App;
