import React, { useLayoutEffect, useState, useRef, useEffect } from 'react';
import ContentBlock from './ContentBlock';
import TankGame from '../TankGame/TankGame.js';

import robot_cup_img from '../../img/projects/roboEdu/robot_cup.gif';
import robot_turn_img from '../../img/projects/roboEdu/robot_turn.gif';
import robot_worm_img from '../../img/projects/roboEdu/robot_worm.gif';

import TextBlock from '../TextBlock/TextBlock';
import './ProjectPage.css';

const RoboPage = (props) => {
    const [show, setShow] = useState({
        blockOne: 'fade-block',
        blockTwo: 'fade-block',
        blockThree: 'fade-block',
    });
    const [windowWidth, setWindowWidth] = useState(800);
    const ref1 = useRef(null),
        ref2 = useRef(null),
        ref3 = useRef(null),
        pageRef = useRef(null);
    useEffect(() => {
        setWindowWidth(window.outerWidth);
    }, []);
    useLayoutEffect(() => {
        const topPos = (element) => element.getBoundingClientRect().top;
        const blockHeight = (element) => element.getBoundingClientRect().height;
        const botPos = (element) => element.getBoundingClientRect().bottom;
        const padding = 50;
        //added to reduce redundancy
        const div1Pos = topPos(ref1.current),
            div2Pos = topPos(ref2.current),
            div3Pos = topPos(ref3.current);

        const onScroll = () => {
            const scrollPos = window.scrollY + window.innerHeight;
            // console.log('block height: ' + blockHeight(ref1.current));
            // console.log('div1Pos : ' + div1Pos);
            // console.log('scroll Y: ' + window.scrollY);
            // console.log('scroll: ' + scrollPos);
            console.log(ref2.current.getBoundingClientRect());
            if (topPos(ref1.current) > 0) {
                setShow((state) => ({
                    ...state,
                    blockOne: 'fade-block show',
                }));
            }
            // } else if (botPos(ref1.current) < padding) {
            //     setShow((state) => ({
            //         ...state,
            //         blockOne: 'fade-block hide',
            //     }));
            // }
            else {
                setShow((state) => ({
                    ...state,
                    blockOne: 'fade-block hide',
                }));
            }
            if (
                topPos(ref2.current) < blockHeight(ref2.current) / 2 &&
                botPos(ref2.current) > padding
            ) {
                setShow((state) => ({
                    ...state,
                    blockTwo: 'fade-block show',
                }));
                console.log('show');
            } else {
                setShow((state) => ({
                    ...state,
                    blockTwo: 'fade-block hide',
                }));
            }
            if (
                topPos(ref3.current) < blockHeight(ref3.current) / 2 &&
                botPos(ref3.current) > padding
            ) {
                setShow((state) => ({
                    ...state,
                    blockThree: 'fade-block show',
                }));
            } else {
                setShow((state) => ({
                    ...state,
                    blockThree: 'fade-block hide',
                }));
            }
        };

        const onResizeWindow = () => {
            setWindowWidth(window.outerWidth);
        };
        window.addEventListener('scroll', onScroll);
        window.addEventListener('resize', onResizeWindow);
        return () => {
            window.removeEventListener('scroll', onScroll);
            window.removeEventListener('resize', onResizeWindow);
        };
    }, []);
    const text1 =
        'Designing and controlling VexIQ robots using RobotC programming. I advise and guide students to accurately control robots equiped with motors and various sensors such as the touch sensors, color sensors and gyro sensors.';
    const text2 =
        'Students have different weaknesses and strengths; they are also different in comfortability with coding. Some requires more coding practice with the basics of controlling the motors to perform turns and moving in straight lines at different speed.';
    const text3 =
        'There are also younger students that need to understand and build the "phyiscs feeling" of robotics. Students are taught with different mechanics of lego pieces to understand how pieces will work together. After building the robot students do basic coding with a drag-and-drop user interface to build up logical thinking';
    var font_size = windowWidth < 600 ? '18px' : '30px';
    var text_box_size = windowWidth < 600 ? '100%' : '50%';
    return (
        <div className='project-page' ref={pageRef}>
            <h1 className='title'>RoboEdu Course Instructor and Developer</h1>
            <TextBlock text='Delivered S.T.E.M. based instructions to encourage students to solve robotic and programming projects in a logical way of thinking.' />
            <div className='container' style={{ maxWidth: '100%' }}>
                <div className={show.blockOne} ref={ref1}>
                    <ContentBlock
                        style={{
                            backgroundColor: 'rgba(74, 74, 87,0.2)',
                            marginTop: '30px',
                            marginBottom: '30px',
                            borderRadius: '5%',
                        }}
                        image={robot_cup_img}
                        imageStyle={{
                            maxWidth: '100%',
                            width: '500px',
                            height: 'auto',
                        }}
                        textStyle={{
                            fontSize: font_size,
                            maxWidth: text_box_size,
                        }}
                        text={text1}
                        direction={windowWidth < 600 ? 'center' : 'left'}
                    />
                </div>
                <div className={show.blockTwo} ref={ref2}>
                    <ContentBlock
                        style={{
                            backgroundColor: 'rgba(74, 74, 87,0.2)',
                            marginTop: '30px',
                            marginBottom: '30px',
                            borderRadius: '5%',
                        }}
                        image={robot_turn_img}
                        imageStyle={{
                            maxWidth: '100%',
                            width: '400px',
                            height: 'auto',
                        }}
                        textStyle={{
                            fontSize: font_size,
                            maxWidth: text_box_size,
                        }}
                        text={text2}
                        direction={windowWidth < 600 ? 'center' : 'right'}
                    />
                </div>
                <div className={show.blockThree} ref={ref3}>
                    <ContentBlock
                        style={{
                            backgroundColor: 'rgba(74, 74, 87,0.2)',
                            marginTop: '30px',
                            marginBottom: '30px',
                            borderRadius: '5%',
                        }}
                        image={robot_worm_img}
                        imageStyle={{
                            maxWidth: '100%',
                            width: '300px',
                            height: 'auto',
                        }}
                        textStyle={{
                            fontSize: font_size,
                            maxWidth: text_box_size,
                        }}
                        text={text3}
                        direction={windowWidth < 600 ? 'center' : 'left'}
                    />
                </div>
            </div>
            <div className='game'>
                <TankGame />
            </div>
            <div className='game available'>
                The game was developed purely in javascript and html and has
                been adapted for this reactjs website. The instructor was
                supposed to teach the very basics of javascript and canvas and
                then guide students to create a game of their own. <br />
                Use arrow keys to control the tank and space-bar to fire
                bullets. <br />
                Press p to pause game.
            </div>
            <div className='game unavailable'>
                <p>
                    Game only available on computers and controllable only with
                    a keyboard!
                </p>
            </div>
        </div>
    );
};

export default RoboPage;
