import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PlaceHolder from '../PlaceHolder/PlaceHolder';
import './ImageBlock.css';

const ImageBlock = (props) => {
    const [isLoaded, setIsLoaded] = useState(false);
    var image_block = '';
    if (props.link) {
        image_block = (
            <Link to={props.link}>
                <img
                    src={props.image}
                    alt={props.alt}
                    style={{
                        ...(isLoaded
                            ? {}
                            : { visibility: 'hidden', position: 'fixed' }),
                        ...props.imageStyle,
                    }}
                    onLoad={() => {
                        setIsLoaded(true);
                    }}
                />
            </Link>
        );
    } else {
        image_block = (
            <img
                src={props.image}
                alt={props.alt}
                style={{
                    ...(isLoaded
                        ? {}
                        : { visibility: 'hidden', position: 'fixed' }),
                    ...props.imageStyle,
                }}
                onLoad={() => {
                    setIsLoaded(true);
                }}
            />
        );
    }
    var directionStyle;
    if (props.direction === 'left') {
        directionStyle = {
            justifyContent: 'flex-start',
        };
    } else if (props.direction === 'right') {
        directionStyle = {
            justifyContent: 'flex-end',
        };
    } else {
        directionStyle = {
            justifyContent: 'center',
        };
    }

    //if (props.imageStyle.height === 'auto') console.log('auto');

    return (
        <div className='image-block' style={directionStyle}>
            {isLoaded ? null : (
                <PlaceHolder //parseInt('245px', 10);
                    width={props.width || parseInt(props.imageStyle.width)}
                    height={
                        props.height ||
                        parseInt(props.imageStyle.height) ||
                        '600'
                    }
                />
            )}
            {image_block}
        </div>
    );
};

export default ImageBlock;
