import React from 'react';
import './ToolBar.css';
import SideBarToggler from '../SideBar/SideBarToggler.js';
import { Link } from 'react-router-dom';

const ToolBar = (props) => {
    return (
        <header className='toolbar'>
            <nav className='toolbar_navigation'>
                <div className='toolbar_sidebar-toggler'>
                    <SideBarToggler clickHandler={props.toggleSideBarHandler} />
                </div>
                <div className='toolbar_logo'>
                    <Link to='/portfolio'>JACOPO JI</Link>
                </div>
                <div className='spacer' />
                <div className='toolbar_navigtaion-items'>
                    <ul>
                        <li>
                            <Link to='/about'>About Me</Link>
                        </li>
                        <li>
                            <Link to='/experience'>Experience</Link>
                        </li>
                        <li>
                            <Link to='/contact'>Contact Me</Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    );
};

export default ToolBar;
