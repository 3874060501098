import React from 'react';
import linkedin_image from '../../img/linkedIn.svg';
import facebook_image from '../../img/facebook.svg';
import github_image from '../../img/github.svg';
import './Footer.css';

const Footer = (props) => {
    return (
        <footer className='footer'>
            <div className='footer-text'>
                <a href='https://github.com/jacopoji'>
                    <img src={github_image} alt='Github'></img>
                </a>
                <a href='https://www.linkedin.com/in/jacopoji/'>
                    <img src={linkedin_image} alt='LinkedIn'></img>
                </a>
                <a href='https://www.facebook.com/jijacopo/'>
                    <img src={facebook_image} alt='Facebook'></img>
                </a>
            </div>
        </footer>
    );
};

export default Footer;
