import React, { useState } from 'react';
import './About.css';
import photo from '../../img/photo-self.png';
import PlaceHolder from '../PlaceHolder/PlaceHolder';

const About = (props) => {
    const [loaded, setLoaded] = useState(false);
    return (
        <div className='about'>
            <div className='about_technical'>
                <h1 className='title'>About Jacopo </h1>
                <div className='about_technical_content'>
                    <img
                        className='about_photo'
                        src={photo}
                        alt='me'
                        style={
                            !loaded
                                ? { visibility: 'hidden', position: 'fixed' }
                                : {}
                        }
                        onLoad={() => {
                            setLoaded(true);
                        }}
                    />
                    {loaded ? null : <PlaceHolder width='295' height='175' />}

                    <p>
                        I was born in Rome, Italy. However, I was raised up in
                        Thessaloniki, Greece and went to Pinewood The American
                        International School of Thessaloniki until Grade 5. Not
                        even knowing how to write my own Chinese name, I went
                        back to China to learn Chinese and stayed in China until
                        graduating from high school. To fulfill my dream in
                        studying programming in an English environment, I went
                        abroad and studied Electrical and Computer Engineering
                        at the University of Toronto. Eventually I graduated in
                        July 2019.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default About;
