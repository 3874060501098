import React from 'react';

import './TextBlock.css';

const TextBlock = (props) => {
    return (
        <div className='text-block'>
            <p style={props.textStyle}>{props.text}</p>
        </div>
    );
};

export default TextBlock;
