import React from 'react';

import ImageCard from '../ImageBlock/ImageCard';
import image_portfolio_logo from '../../img/projects/personalProject/portfolio/logo.png';

import './Projects.css';

const PersonalProjects = (props) => {
    return (
        <div className='project-body'>
            <h1 className='project-title'>Personal Projects</h1>
            <div className='project-content'>
                <ul>
                    <li>
                        <ImageCard
                            image={image_portfolio_logo}
                            text='Personal portfolio written in React'
                            link='/'
                            width='350'
                            height='200'
                            alt='Personal portfolio'
                        />
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default PersonalProjects;
