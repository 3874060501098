import React from 'react';

import ImageCard from '../ImageBlock/ImageCard';

import image_map_logo from '../../img/projects/courseWork/geographyMap/logo.png';
import image_se_logo from '../../img/projects/courseWork/searchEngine/logo.jpg';
import './Projects.css';

const CourseProjects = (props) => {
    return (
        <div className='project-body'>
            <h1 className='project-title'>Course Projects</h1>
            <div className='project-content'>
                <ul>
                    <li>
                        <ImageCard
                            image={image_map_logo}
                            text='Geographic Information System'
                            link='/about'
                            width='350'
                            height='200'
                            alt='Geographic Information System project'
                        />
                    </li>

                    <li>
                        <ImageCard
                            image={image_se_logo}
                            text='Search Engine and data crawler'
                            link='/about'
                            width='350'
                            height='200'
                            alt='Search engine project'
                        />
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default CourseProjects;
