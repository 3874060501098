import React from 'react';
import ImageBlock from './ImageBlock';

import './ImageCard.css';

const ImageCard = (props) => {
    return (
        <div className='image-card'>
            <ImageBlock {...props} />
            <div className='description'>{props.text}</div>
        </div>
    );
};

ImageCard.defaultProps = {
    text: 'Text Placeholder',
};

export default ImageCard;
