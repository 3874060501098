import React, { useRef, useEffect } from 'react';
import './TankGame.css';
import gameLogo from './logo.jpg';
const TankGame = (props) => {
    const canvasRef = useRef(null);
    const imgRef = useRef(null);
    const requestRef = useRef(null);
    const context = useRef(null);
    const global_tick = useRef(null);
    const dpi = useRef(null);
    const keyboardEnable = useRef(false);
    var keys_pressed = [];

    const canvas = useRef(null);
    const image = useRef(null);
    //boundaries
    var boundary_thickness = 20;
    var padding = 50;
    var sprite_scale = 0.75;
    var boundary_left = 0 + boundary_thickness + padding;
    var boundary_top = 0 + boundary_thickness + padding;
    var boundary_right;
    var boundary_bottom;

    var tank_boundary_left = 31;
    var tank_boundary_right = 41;
    var tank_boundary_top = 33;
    var tank_boundary_bottom = 38;
    var rand_direction;
    var newAmmo;
    var newAmmo2;
    var newAmmo3;
    var myTank;
    var theHitTank;
    var newPowerup;

    //game configuration
    var playerMovementSpeed = 10;
    var playerMaxHp = 2;
    var lives = 3;
    var spawn_point = [350, 450];
    var powerup_duration = 900;

    const tanks = useRef([]); // an array to keep track of every tank
    var ammos = []; // an array to keep track of every ammo
    var powerups = []; // an array to keep track of every powerup

    var current_level = -1;
    var score = 0;

    useEffect(() => {
        dpi.current = window.devicePixelRatio;
        global_tick.current = 0;
        canvas.current = canvasRef.current;
        context.current = canvas.current.getContext('2d');
        fix_dpi(canvas.current);

        context.current.clearRect(0, 0, window.innerHeight, window.innerWidth);
        image.current = imgRef.current;
        image.current.onload = () => {
            requestRef.current = requestAnimationFrame(draw);
        };

        //
        if (localStorage.score !== undefined) {
            console.log('Do not need to initialize');
        } else {
            localStorage.setItem('score', '0');
        }

        //event listener for keys being pressed down
        window.addEventListener('keydown', keyBoardEvents, false);
        window.addEventListener('keyup', keyBoardRelease, false);
        return () => {
            cancelAnimationFrame(requestRef.current);
            window.removeEventListener('keydown', keyBoardEvents);
            window.removeEventListener('keyup', keyBoardRelease);
        };
    }, []);
    const enableKeyboardInput = () => {
        keyboardEnable.current = true;
    };
    const disableKeyboardInput = () => {
        keyboardEnable.current = false;
    };
    function tank(xPos, yPos, color, direction, npc, hp) {
        this.xPos = xPos;
        this.yPos = yPos;
        this.color = color;
        this.direction = direction;
        this.npc = npc;
        this.hp = hp;
        this.maxhp = hp;
        this.ticks = 0;
        this.respawning = false;
        this.shooting_interval = 0;
        this.has_powerup = false; // we did not consume powerup by default
        this.powerup_remain = 0;
    }

    function draw_tank(x, y, color) {
        //function to draw tanks
        var left_wheel = [x - 15, y - 15, 10, 30];
        var right_wheel = [x + 15, y - 15, 10, 30];
        var body_1 = [x - 5, y - 5, 20, 10];
        var body_2 = [x - 3, y - 7, 16, 15];
        var cannon_body = [x + 4, y - 30, 3, 30];
        var cannon_head = [x - 2, y - 30, 15, 5];
        var game_pieces = [
            left_wheel,
            body_1,
            body_2,
            right_wheel,
            cannon_body,
            cannon_head,
        ];
        context.current.beginPath();
        context.current.fillStyle = color;
        for (var i = 0; i < game_pieces.length; i++) {
            context.current.fillRect(
                game_pieces[i][0],
                game_pieces[i][1],
                game_pieces[i][2],
                game_pieces[i][3]
            );
        }
        context.current.closePath();
    }

    tank.prototype.update = function () {
        //this function is responsible for drawing the tanks and movements of tanks
        boundary_right = canvas.current.width - boundary_thickness - padding;
        boundary_bottom = canvas.current.height - boundary_thickness - padding;
        if (this.npc && this.ticks >= 60) {
            //enemy random movement direction
            rand_direction = Math.floor(Math.random() * 4);
            if (rand_direction === 0) {
                //this.yPos += 5;
                this.direction = 0;
            } else if (rand_direction === 1) {
                //this.yPos -= 5;
                this.direction = 180;
            } else if (rand_direction === 2) {
                //this.xPos += 5;
                this.direction = 90;
            } else if (rand_direction === 3) {
                //this.xPos -= 5;
                this.direction = 270;
            }
            this.ticks = 0;
        }

        if (this.npc && this.ticks % 5 === 0) {
            //makes enemy move forward and prevent enemy from moving through boundary
            if (this.direction === 0) {
                if (
                    this.yPos >=
                    tank_boundary_top * sprite_scale + boundary_top
                ) {
                    this.yPos -= 5;
                }
            } else if (this.direction === 90) {
                if (
                    this.xPos <=
                    boundary_right - tank_boundary_right * sprite_scale
                ) {
                    this.xPos += 5;
                }
            } else if (this.direction === 180) {
                if (
                    this.yPos <=
                    boundary_bottom - tank_boundary_bottom * sprite_scale
                ) {
                    this.yPos += 5;
                }
            } else if (this.direction === 270) {
                if (
                    this.xPos >=
                    tank_boundary_left * sprite_scale + boundary_left
                ) {
                    this.xPos -= 5;
                }
            }
        }
        if (this.npc && this.shooting_interval > 40) {
            //enemy shooting
            if (this.direction === 0) {
                newAmmo = new ammo(
                    this.xPos + 2 * sprite_scale,
                    this.yPos - 30 * sprite_scale,
                    this.direction
                );
            } else if (this.direction === 90) {
                newAmmo = new ammo(
                    this.xPos + 30 * sprite_scale,
                    this.yPos,
                    this.direction
                );
            } else if (this.direction === 180) {
                newAmmo = new ammo(
                    this.xPos + 2 * sprite_scale,
                    this.yPos + 30 * sprite_scale,
                    this.direction
                );
            } else if (this.direction === 270) {
                newAmmo = new ammo(
                    this.xPos - 30 * sprite_scale,
                    this.yPos,
                    this.direction
                );
            }
            ammos.push(newAmmo);
            this.shooting_interval = 0;
        }

        if (!this.npc && this.ticks % 3 === 0) {
            //user keyboard controlled movement
            if (keys_pressed[37]) {
                // console.log(lives + ' ' + current_level);
                // console.log(tanks.current.length);
                // for (let j = 0; j < tanks.current.length; j++) {
                //     console.log(
                //         'x:' +
                //             tanks.current[j].random_x +
                //             ' y:' +
                //             tanks.current[j].random_y +
                //             ' hp:' +
                //             tanks.current[j].hp
                //     );
                // }
                if (
                    myTank.xPos >=
                    tank_boundary_left * sprite_scale + boundary_left
                ) {
                    myTank.xPos = myTank.xPos - playerMovementSpeed;
                }
                myTank.direction = 270;
            } else if (keys_pressed[38]) {
                if (
                    myTank.yPos >=
                    tank_boundary_top * sprite_scale + boundary_top
                ) {
                    myTank.yPos = myTank.yPos - playerMovementSpeed;
                }
                myTank.direction = 0;
            } else if (keys_pressed[39]) {
                if (
                    myTank.xPos <=
                    boundary_right - tank_boundary_right * sprite_scale
                ) {
                    myTank.xPos = myTank.xPos + playerMovementSpeed;
                }
                myTank.direction = 90;
            } else if (keys_pressed[40]) {
                if (
                    myTank.yPos <=
                    boundary_bottom - tank_boundary_bottom * sprite_scale
                ) {
                    myTank.yPos = myTank.yPos + playerMovementSpeed;
                }
                myTank.direction = 180;
            }
        }

        if (!this.npc && this.shooting_interval > 10) {
            //user keyboard controlled shooting
            if (keys_pressed[32] && !this.respawning) {
                //not allowed to shoot when respawning
                if (myTank.direction === 0) {
                    if (this.has_powerup) {
                        newAmmo2 = new ammo(
                            myTank.xPos + 2 * sprite_scale,
                            myTank.yPos - 30 * sprite_scale,
                            myTank.direction - 15,
                            'red'
                        );
                        newAmmo3 = new ammo(
                            myTank.xPos + 2 * sprite_scale,
                            myTank.yPos - 30 * sprite_scale,
                            myTank.direction + 15,
                            'red'
                        );
                        newAmmo = new ammo(
                            myTank.xPos + 2 * sprite_scale,
                            myTank.yPos - 30 * sprite_scale,
                            myTank.direction,
                            'red'
                        );
                        ammos.push(newAmmo);
                        ammos.push(newAmmo2);
                        ammos.push(newAmmo3);
                    } else {
                        newAmmo = new ammo(
                            myTank.xPos + 2 * sprite_scale,
                            myTank.yPos - 30 * sprite_scale,
                            myTank.direction,
                            'red'
                        );
                        ammos.push(newAmmo);
                    }
                } else if (myTank.direction === 90) {
                    if (this.has_powerup) {
                        newAmmo = new ammo(
                            myTank.xPos + 30 * sprite_scale,
                            myTank.yPos,
                            myTank.direction,
                            'red'
                        );
                        newAmmo2 = new ammo(
                            myTank.xPos + 30 * sprite_scale,
                            myTank.yPos,
                            myTank.direction - 15,
                            'red'
                        );
                        newAmmo3 = new ammo(
                            myTank.xPos + 30 * sprite_scale,
                            myTank.yPos,
                            myTank.direction + 15,
                            'red'
                        );
                        ammos.push(newAmmo);
                        ammos.push(newAmmo2);
                        ammos.push(newAmmo3);
                    } else {
                        newAmmo = new ammo(
                            myTank.xPos + 30 * sprite_scale,
                            myTank.yPos,
                            myTank.direction,
                            'red'
                        );
                        ammos.push(newAmmo);
                    }
                } else if (myTank.direction === 180) {
                    if (this.has_powerup) {
                        newAmmo = new ammo(
                            myTank.xPos + 2 * sprite_scale,
                            myTank.yPos + 30 * sprite_scale,
                            myTank.direction,
                            'red'
                        );
                        newAmmo2 = new ammo(
                            myTank.xPos + 2 * sprite_scale,
                            myTank.yPos + 30 * sprite_scale,
                            myTank.direction - 15,
                            'red'
                        );
                        newAmmo3 = new ammo(
                            myTank.xPos + 2 * sprite_scale,
                            myTank.yPos + 30 * sprite_scale,
                            myTank.direction + 15,
                            'red'
                        );
                        ammos.push(newAmmo);
                        ammos.push(newAmmo2);
                        ammos.push(newAmmo3);
                    } else {
                        newAmmo = new ammo(
                            myTank.xPos + 2 * sprite_scale,
                            myTank.yPos + 30 * sprite_scale,
                            myTank.direction,
                            'red'
                        );
                        ammos.push(newAmmo);
                    }
                } else if (myTank.direction === 270) {
                    if (this.has_powerup) {
                        newAmmo = new ammo(
                            myTank.xPos - 30 * sprite_scale,
                            myTank.yPos,
                            myTank.direction,
                            'red'
                        );
                        newAmmo2 = new ammo(
                            myTank.xPos - 30 * sprite_scale,
                            myTank.yPos,
                            myTank.direction - 15,
                            'red'
                        );
                        newAmmo3 = new ammo(
                            myTank.xPos - 30 * sprite_scale,
                            myTank.yPos,
                            myTank.direction + 15,
                            'red'
                        );
                        ammos.push(newAmmo);
                        ammos.push(newAmmo2);
                        ammos.push(newAmmo3);
                    } else {
                        newAmmo = new ammo(
                            myTank.xPos - 30 * sprite_scale,
                            myTank.yPos,
                            myTank.direction,
                            'red'
                        );
                        ammos.push(newAmmo);
                    }
                }
                this.shooting_interval = 0;
            }
        }

        this.ticks += 1;
        this.shooting_interval += 1;

        //draw tanks
        context.current.save();
        context.current.translate(this.xPos + 5, this.yPos + 3);
        context.current.rotate((this.direction * Math.PI) / 180); // rotate canvas if the tanks are facing another angle
        context.current.scale(sprite_scale, sprite_scale); // scale the tanks according to the sprite_scale variable
        context.current.translate(-this.xPos - 5, -this.yPos - 3);
        if (
            this.npc ||
            (!this.npc && !this.respawning) ||
            (!this.npc && this.respawning && this.ticks % 60 < 30)
        ) {
            draw_tank(this.xPos, this.yPos, this.color);
            if (!this.npc && this.respawning && this.ticks >= 300) {
                // make the flashing animation when the tank is respawning
                this.ticks = 0;
                this.respawning = false;
            }
        }
        context.current.translate(this.xPos, this.yPos);
        context.current.rotate((-this.direction * Math.PI) / 180); //un-rotate the canvas so things don't get messed up
        context.current.translate(-this.xPos, -this.yPos);
        context.current.restore();

        //draw tank health bars
        context.current.beginPath();
        context.current.fillStyle = 'green';
        context.current.strokeStyle = 'white';
        context.current.lineWidth = 2;
        if (!this.respawning) {
            for (var i = 0; i < this.hp; i++) {
                context.current.rect(
                    this.xPos -
                        14 * sprite_scale +
                        (40 / this.maxhp) * i * sprite_scale,
                    this.yPos - 40 * sprite_scale,
                    (sprite_scale * 40) / this.maxhp,
                    5
                );
            }
        }
        context.current.closePath();
        context.current.fill();
        context.current.stroke();

        //draw remaining lives (bottom left blue tanks)
        context.current.save();
        context.current.scale(0.5, 0.5);
        for (i = 0; i < lives; i++) {
            draw_tank(
                boundary_left * 2 + i * 50,
                canvas.current.height * 2 - boundary_thickness * 2,
                'blue'
            );
        }
        context.current.restore();

        //draw powerup remaining time
        if (!this.npc && this.powerup_remain > 0) {
            context.current.fillStyle = '#99ccff';
            context.current.beginPath();
            context.current.fillRect(
                boundary_right - boundary_left,
                boundary_bottom + boundary_thickness * 2,
                (100 * this.powerup_remain) / powerup_duration,
                10
            );
            context.current.closePath();
            this.powerup_remain -= 1;
            if (this.powerup_remain === 0) {
                //if we don't have any time remaining for powerup, then mark has_powerup as false
                this.has_powerup = false;
            }
        }
    };

    function ammo(xPos, yPos, direction, color = '#33ccff') {
        /* Define the data structure for the ammos*/
        this.xPos = xPos;
        this.yPos = yPos;
        this.direction = direction;
        this.color = color;
        this.ticks = 0;
    }

    ammo.prototype.update = function () {
        /* Controls the speed of the ammo and also draws the ammo*/
        var steps = 20;
        if (this.ticks % 3 === 0) {
            this.xPos += Math.sin((this.direction * Math.PI) / 180) * steps;
            this.yPos += -Math.cos((this.direction * Math.PI) / 180) * steps;
            this.ticks = 0;
        }
        this.ticks += 1;
        context.current.beginPath();
        context.current.fillStyle = this.color;
        context.current.fillRect(this.xPos, this.yPos, 6, 6);
        context.current.closePath();
    };

    function powerUp(xPos, yPos) {
        //power up object
        this.xPos = xPos;
        this.yPos = yPos;
        this.width = 20;
        this.height = 20;
        this.color = '#ccffcc';
        this.timer = 0;
    }

    powerUp.prototype.update = function () {
        context.current.beginPath();
        context.current.strokeStyle = this.color;
        context.current.rect(this.xPos, this.yPos, this.width, this.height);
        context.current.moveTo(this.xPos, this.yPos);
        context.current.lineTo(this.xPos + this.width, this.yPos + this.height);
        context.current.moveTo(this.xPos + this.width, this.yPos);
        context.current.lineTo(this.xPos, this.yPos + this.height);
        context.current.stroke();
        context.current.closePath();
        this.timer += 1;
    };

    function hit_boundary(x, y) {
        /* A helper function to check if touching edfe or not*/
        boundary_right = canvas.current.width - boundary_thickness - padding;
        boundary_bottom = canvas.current.height - boundary_thickness - padding;
        if (
            x <= boundary_left ||
            x >= boundary_right ||
            y >= boundary_bottom ||
            y <= boundary_top
        ) {
            return true;
        } else {
            return false;
        }
    }

    function hit_tank(ammo, tank_list) {
        for (var i = 0; i < tank_list.length; i++) {
            //first check if an ammo hits a tank or not
            if (
                ammo.xPos > tank_list[i].xPos - 20 &&
                ammo.xPos < tank_list[i].xPos + 20 &&
                ammo.yPos > tank_list[i].yPos - 20 &&
                ammo.yPos < tank_list[i].yPos + 20
            ) {
                //then check if the ammo is friendly or not
                if (
                    (ammo.color === 'red' && tank_list[i].color === 'yellow') ||
                    (ammo.color === '#33ccff' && tank_list[i].color === 'blue')
                ) {
                    //also check if respawning or not, if respawning then the tank should not take damage
                    if (!tank_list[i].respawning) {
                        return i;
                    }
                }
            }
        }
        return -1;
    }

    function touch_powerup(powerup, userTank) {
        //check if the powerup and user's tank is intersecting or not

        //check all four corners of the powerup
        if (
            powerup.xPos > userTank.xPos - 20 &&
            powerup.xPos < userTank.xPos + 20 &&
            powerup.yPos > userTank.yPos - 20 &&
            powerup.yPos < userTank.yPos + 20
        ) {
            return true;
        } else if (
            powerup.xPos + powerup.width > userTank.xPos - 20 &&
            powerup.xPos + powerup.width < userTank.xPos + 20 &&
            powerup.yPos > userTank.yPos - 20 &&
            powerup.yPos < userTank.yPos + 20
        ) {
            return true;
        } else if (
            powerup.xPos > userTank.xPos - 20 &&
            powerup.xPos < userTank.xPos + 20 &&
            powerup.yPos + powerup.height > userTank.yPos - 20 &&
            powerup.yPos + powerup.height < userTank.yPos + 20
        ) {
            return true;
        } else if (
            powerup.xPos + powerup.width > userTank.xPos - 20 &&
            powerup.xPos + powerup.width < userTank.xPos + 20 &&
            powerup.yPos + powerup.height > userTank.yPos - 20 &&
            powerup.yPos + powerup.height < userTank.yPos + 20
        ) {
            return true;
        } else {
            return false;
        }
    }

    function create_tanks(hp = 1) {
        /* A function to create enemy tanks and start the game*/
        boundary_right = canvas.current.width - boundary_thickness - padding;
        boundary_bottom = canvas.current.height - boundary_thickness - padding;
        for (var i = 0; i < 3 + current_level; i++) {
            var random_x =
                Math.floor(Math.random() * (boundary_right - boundary_left)) +
                boundary_left;
            var random_y =
                Math.floor(
                    Math.random() * (boundary_bottom / 2 - boundary_top / 2)
                ) +
                boundary_top +
                boundary_thickness;

            rand_direction = Math.floor(Math.random() * 4);
            if (rand_direction === 0) {
                rand_direction = 0;
            } else if (rand_direction === 1) {
                rand_direction = 180;
            } else if (rand_direction === 2) {
                rand_direction = 90;
            } else if (rand_direction === 3) {
                rand_direction = 270;
            }

            var enemy_tank = new tank(
                random_x,
                random_y,
                'yellow',
                rand_direction,
                true,
                hp
            );
            tanks.current.push(enemy_tank);
        }

        // var tank2 = new tank(200, 100, 'yellow', 90, true, hp);
        // var tank3 = new tank(100, 200, 'yellow', 270, true, hp);
        // tanks.current.push(tank2);
        // tanks.current.push(tank3);
    }

    function keyBoardEvents(event) {
        /* Events that will happen when certain keys on the keyboard are pressed */
        //find keyCode here https://css-tricks.com/snippets/javascript/javascript-keycodes/
        if (keyboardEnable.current === false) return;
        if (event.keyCode === 80) {
            keys_pressed[event.keyCode] = !keys_pressed[event.keyCode];
            if (keys_pressed[event.keyCode] === false) {
                requestRef.current = requestAnimationFrame(draw);
            }
        } else {
            keys_pressed[event.keyCode] = true;
        }

        if (lives <= -1 && keys_pressed[13]) {
            console.log('Game restart');
            current_level = 1;
            lives = 3;
            score = 0;
            myTank = new tank(
                spawn_point[0],
                spawn_point[1],
                'blue',
                0,
                false,
                playerMaxHp
            );
            tanks.current.push(myTank);
            create_tanks();
        }

        if (current_level === -1 && keys_pressed[13]) {
            console.log('Game start');
            current_level = 1;
            lives = 3;
            score = 0;
            myTank = new tank(
                spawn_point[0],
                spawn_point[1],
                'blue',
                0,
                false,
                playerMaxHp
            );
            tanks.current.push(myTank);
            create_tanks();
        }
        event.preventDefault();
    }
    function keyBoardRelease(event) {
        if (event.keyCode !== 80) keys_pressed[event.keyCode] = false;
    }

    function fix_dpi(canvas) {
        //get CSS height
        //the + prefix casts it to an integer
        //the slice method gets rid of "px"
        let style_height = +getComputedStyle(canvas)
            .getPropertyValue('height')
            .slice(0, -2);
        //get CSS width
        let style_width = +getComputedStyle(canvas)
            .getPropertyValue('width')
            .slice(0, -2);
        //scale the canvas
        canvas.setAttribute('height', style_height * dpi.current);
        canvas.setAttribute('width', style_width * dpi.current);
    }

    function draw() {
        /* The main drawing function that is called repeatedly to animate our game */
        if (keys_pressed[80]) return;
        boundary_right = canvas.current.width - boundary_thickness - padding;
        boundary_bottom = canvas.current.height - boundary_thickness - padding;
        context.current.clearRect(
            0,
            0,
            canvas.current.width,
            canvas.current.height
        );
        // according to the painters model, we paint background/border first, then the tanks and other elements.

        //first we draw the green borders
        context.current.fillStyle = 'green';
        context.current.fillRect(
            0 + padding,
            0 + padding,
            canvas.current.width - padding * 2,
            boundary_thickness
        );
        context.current.fillRect(
            0 + padding,
            0 + padding,
            boundary_thickness,
            canvas.current.height - padding * 2
        );
        context.current.fillRect(
            canvas.current.width - boundary_thickness - padding,
            0 + padding,
            boundary_thickness,
            canvas.current.height - padding * 2
        );
        context.current.fillRect(
            0 + padding,
            canvas.current.height - boundary_thickness - padding,
            canvas.current.width - padding * 2,
            boundary_thickness
        );
        //then we draw our current level
        if (current_level > 0) {
            context.current.strokeStyle = 'white';
            context.current.font = '30px Arial';
            context.current.textAlign = 'center';
            context.current.beginPath();
            context.current.strokeText(
                'Level ' + current_level,
                canvas.current.width / 2,
                boundary_top / 2
            );
            context.current.closePath();
        }

        //then we draw our current score
        if (current_level > 0) {
            context.current.strokeStyle = 'white';
            context.current.font = '30px Arial';
            context.current.textAlign = 'center';
            context.current.beginPath();
            context.current.strokeText(
                'Score: ' + score,
                canvas.current.width - boundary_thickness * 8,
                boundary_top / 2
            );
            context.current.closePath();
        }
        //draw high score
        context.current.fillStyle = 'white';
        context.current.font = '30px Arial';
        context.current.textAlign = 'center';
        context.current.beginPath();
        context.current.fillText(
            'High Score: ' +
                (localStorage.score !== undefined ? localStorage.score : 'N/A'),
            boundary_thickness * 8,
            boundary_top / 2
        );
        context.current.closePath();
        if (current_level === -1) {
            //this means game has not started, draw welcome frame
            context.current.strokeStyle = 'white';
            context.current.fillStyle = 'white';
            context.current.font = '60px Arial';
            context.current.textAlign = 'center';
            context.current.beginPath();
            // var gameLogo = new Image();
            // gameLogo.src = '/logo.jpg';
            // const gameLogoImg = new Image();
            // gameLogoImg.src = 'logo.jpg';
            // gameLogoImg.onload = () => {
            context.current.drawImage(
                image.current,
                canvas.current.width / 3,
                canvas.current.height / 4 - boundary_thickness * 6,
                canvas.current.width / 3,
                canvas.current.height / 3
            );
            // };

            context.current.fillText(
                'WELCOME TO TANK WORLD',
                canvas.current.width / 2,
                canvas.current.height / 2
            );
            context.current.font = '40px Arial';
            context.current.strokeText(
                'GAME BY JACOPO JI',
                canvas.current.width / 2,
                canvas.current.height - boundary_thickness * 10
            );
            if (global_tick.current % 60 < 30) {
                context.current.font = '30px Arial';
                context.current.fillStyle = 'white';
                context.current.fillText(
                    'Press [Enter] to start the game',
                    canvas.current.width / 2,
                    canvas.current.height - boundary_thickness * 5
                );
            }
            context.current.closePath();
        }
        if (lives <= -1) {
            tanks.current = [];
            ammos = [];
            powerups = [];
            context.current.fillStyle = 'white';
            context.current.strokeStyle = 'white';
            context.current.font = '90px Arial';
            context.current.textAlign = 'center';
            context.current.beginPath();
            context.current.strokeText(
                'GAME END!',
                canvas.current.width / 2,
                boundary_top * 4
            );
            context.current.font = '50px Arial';
            context.current.fillText(
                'Your final score is: ' + score,
                canvas.current.width / 2,
                canvas.current.height / 2 + 30
            );
            if (score > localStorage.score) {
                localStorage.setItem('score', score);
            }

            if (global_tick.current % 60 < 30) {
                context.current.fillText(
                    'Press [Enter] to start another game!!!',
                    canvas.current.width / 2,
                    canvas.current.height / 2 + 90
                );
            }
            context.current.closePath();
        }
        //then we draw the tanks and ammos
        for (var i = 0; i < tanks.current.length; i++) {
            tanks.current[i].update();
        }
        for (var j = 0; j < ammos.length; j++) {
            if (hit_boundary(ammos[j].xPos, ammos[j].yPos)) {
                ammos.splice(j, 1);
            } else if (hit_tank(ammos[j], tanks.current) !== -1) {
                theHitTank = hit_tank(ammos[j], tanks.current);
                if (theHitTank === 0) {
                    ammos.splice(j, 1);
                    myTank.hp -= 1;
                    if (myTank.hp === 0) {
                        lives -= 1;
                        myTank.ticks = 0;
                        myTank.hp = playerMaxHp;
                        myTank.xPos = spawn_point[0];
                        myTank.yPos = spawn_point[1];
                        myTank.respawning = true;
                        myTank.has_powerup = false; //if we die then we remove powerup
                        myTank.powerup_remain = 0; //as above
                    }
                    if (lives <= -1) {
                        tanks.current.shift();
                    }
                    break;
                } else {
                    ammos.splice(j, 1);
                    tanks.current[theHitTank].hp -= 1;
                    if (tanks.current[theHitTank].hp === 0) {
                        tanks.current.splice(theHitTank, 1);
                        score += 1;
                    }
                    break;
                }
            } else {
                ammos[j].update();
            }
        }
        //then we draw powerups (if there are any)
        for (var k = 0; k < powerups.length; k++) {
            if (touch_powerup(powerups[k], tanks.current[0])) {
                powerups.splice(k, 1);
                tanks.current[0].has_powerup = true;
                tanks.current[0].powerup_remain = powerup_duration;
                break;
            } else if (powerups[k].timer >= 300) {
                powerups.splice(k, 1);
                break;
            } else {
                powerups[k].update();
            }
        }

        if (global_tick.current % 600 === 0 && current_level !== -1) {
            var random_x =
                Math.floor(Math.random() * (boundary_right - boundary_left)) +
                boundary_left;
            var random_y =
                Math.floor(
                    Math.random() * (boundary_bottom / 2 - boundary_top / 2)
                ) +
                boundary_top +
                boundary_thickness;
            newPowerup = new powerUp(random_x, random_y);
            powerups.push(newPowerup);
        }

        //Check if we beat all enemy tanks, if yes, increase level
        if (tanks.current.length === 1 && !tanks.current[0].npc && lives >= 0) {
            current_level += 1;
            create_tanks(current_level);
        }
        global_tick.current += 1;
        requestRef.current = requestAnimationFrame(draw);
    }

    return (
        <div className='body'>
            <canvas
                ref={canvasRef}
                onFocus={enableKeyboardInput}
                onBlur={disableKeyboardInput}
                tabIndex='0'
            />
            <img
                ref={imgRef}
                alt='tank logo'
                src={gameLogo}
                style={{ display: 'none' }}
            />
        </div>
    );
};

export default TankGame;
