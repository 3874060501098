import React, { useState } from 'react';

import lightbulb_on from '../../bgimg/light_bulb_resized.png';
import lightbulb_off from '../../bgimg/light_bulb_resized_bw.png';

import './Home.css';

const Home = (props) => {
    const [lightOn, setLightOn] = useState(true);
    // const [lightBulbLocation, setLightBulbLocation] = useState({
    //     height: '80px',
    // });
    // const [bodyBlockPosition, setBodyBlockPosition] = useState(
    //     window.innerHeight / 2
    // );
    // const lightBulbRef = useRef(null);
    // const bodyBlockRef = useRef(null);
    // useEffect(() => {
    //     setLightBulbLocation(lightBulbRef.current.getBoundingClientRect());
    //     setBodyBlockPosition(() => {
    //         var rect = bodyBlockRef.current.getBoundingClientRect();
    //         // console.log(rect.top + rect.height / 2);
    //         return rect.top + rect.height / 2;
    //     });
    // }, []);
    // useLayoutEffect(() => {
    //     const handleResize = () => {
    //         setLightBulbLocation(lightBulbRef.current.getBoundingClientRect());
    //         // console.log(lightBulbRef.current.getBoundingClientRect());
    //     };

    //     window.addEventListener('resize', handleResize);
    //     return () => window.removeEventListener('resize', handleResize);
    // }, []);
    var lightOffStyle;
    if (lightOn === false) {
        lightOffStyle = { boxShadow: '0px 0px 0px 0px', animation: 'none' };
    }
    // var leftLightStyle, rightLightStyle;
    // if (!isNaN(lightBulbLocation.height) && window.outerWidth < 600) {
    //     leftLightStyle = {
    //         top: -(
    //             bodyBlockPosition -
    //             lightBulbLocation.top +
    //             lightBulbLocation.height / 2
    //         ),
    //     };
    //     rightLightStyle = {
    //         top:
    //             bodyBlockPosition -
    //             lightBulbLocation.top +
    //             lightBulbLocation.height / 2,
    //     };
    // } else {
    //     leftLightStyle = {
    //         top: -80,
    //     };
    //     rightLightStyle = {
    //         top: 80,
    //     };
    // }

    return (
        <div className='home'>
            <div className='body-block'>
                <div className='body-block_left' style={{ ...lightOffStyle }}>
                    Adaptable <br /> Programmer
                </div>

                <img
                    className='background-lightbulb'
                    src={lightOn ? lightbulb_on : lightbulb_off}
                    alt='lightbulb'
                    onClick={() => {
                        setLightOn(!lightOn);
                    }}
                    //style={{ 'pointer-events': 'all' }}
                ></img>
                <div className='body-block_right' style={{ ...lightOffStyle }}>
                    Continuously Learning <br />
                    Improving
                </div>
            </div>
        </div>
    );
};

export default Home;
