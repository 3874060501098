import React, { useLayoutEffect, useRef, useState } from 'react';
import ContentBlock from './ContentBlock';

import front_img from '../../img/projects/hdr/front.jpg';
import lane_detection_img from '../../img/projects/hdr/laneDetection.gif';
import city_hdr_img from '../../img/projects/hdr/cityHDR.gif';
import edge_detection_img from '../../img/projects/hdr/edgeDetection.png';
import me_img from '../../img/projects/hdr/me.jpg';

import './ProjectPage.css';

const HDRPage = (props) => {
    const pageRef = useRef(null);
    const [windowWidth, setWindowWidth] = useState(window.outerWidth);

    useLayoutEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    var text1 =
        'At Mann Lab we had teams working on the Real-time HDR vision system on automobiles. We had a team working on the mechanical mounting and lens adapters for the FLIR high speed camera. We also had a software team working on the software that controls the camera. The software team is responsible for writing automated data requisition, automated camera configuration and implement multi-threading using the multi-processing package in Python.';

    var text2 =
        'Jacopo was fully responsible of and implemented the lane detection function and display. The frames stacking on top of each other are frames of different camera exposure time intervals. The one on top of the black block is the resulting HDR frame. The blue path is the resulting lane detection implemented on the "best looking" frame and the HDR frame to compare results. The frames are captured by the FLIR pyspin module and displayed by openCV';

    var text3 =
        'Jacopo was also fully responsible of controlling the camera exposure time to maintain good image quality being fed into the HDR system. Note the change of exposure time of the camera when the car goes under a bridge.';

    var text4 =
        'Jacopo also implemented edge detection to be displayed on the view. The key "e" or "E" on the keyboard was used to switch between normal mode and edge detection mode.';

    var text5 =
        'And a picture of me going out for a testing ride to record the test :)';
    return (
        <div className='project-page' ref={pageRef}>
            <h1 className='title'>
                Real-time High Dynamic Range Vision System
            </h1>
            <ContentBlock
                image={front_img}
                imageStyle={{
                    width:
                        windowWidth < 600
                            ? Math.floor(windowWidth) - 30 + 'px'
                            : '500px',
                    height: '300px',
                }}
                text={text1}
                direction={windowWidth < 1000 ? 'center' : 'left'}
            />
            <ContentBlock
                image={lane_detection_img}
                imageStyle={{
                    maxWidth: '100%',
                    width: windowWidth < 1200 ? '600px' : '800px',
                    height: 'auto',
                }}
                text={text2}
                direction={windowWidth < 1000 ? 'center' : 'right'}
            />
            <ContentBlock
                image={city_hdr_img}
                imageStyle={{
                    maxWidth: '100%',
                    width:
                        windowWidth < 1200
                            ? windowWidth < 600
                                ? Math.floor(windowWidth) + 'px'
                                : '600px'
                            : '800px',
                    height: 'auto',
                }}
                text={text3}
                direction={windowWidth < 1000 ? 'center' : 'left'}
            />

            <ContentBlock
                image={edge_detection_img}
                imageStyle={{
                    width:
                        windowWidth < 600
                            ? Math.floor(windowWidth) - 30 + 'px'
                            : '600px',
                    height: 'auto',
                }}
                text={text4}
                direction={windowWidth < 1000 ? 'center' : 'right'}
            />
            <ContentBlock
                image={me_img}
                imageStyle={{
                    width:
                        windowWidth < 600
                            ? Math.floor(windowWidth) - 30 + 'px'
                            : '600px',
                    height: 'auto',
                }}
                text={text5}
                direction={windowWidth < 1000 ? 'center' : 'left'}
            />
        </div>
    );
};

export default HDRPage;
