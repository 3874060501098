import React, { useLayoutEffect, useRef, useState } from 'react';

import ImageCard from '../ImageBlock/ImageCard';
import image_hdr_logo from '../../img/projects/hdr/logo.jpg';
import image_native_logo from '../../img/projects/inventoryApp/appImage.PNG';
import image_course_work_logo from '../../img/projects/courseWork/logo.png';
import image_personal_project_logo from '../../img/projects/personalProject/logo.png';
import image_robo_logo from '../../img/projects/roboEdu/logo.jpeg';
import './Projects.css';

const Projects = (props) => {
    const [pageWidth, setPageWidth] = useState(350);
    const pageRef = useRef(null);
    useLayoutEffect(() => {
        const handleResize = () => {
            setPageWidth(window.outerWidth);
            console.log(window.outerWidth + ',' + window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return (
        <div className='project-body' ref={pageRef}>
            <h1 className='project-title'>Experiences</h1>
            <div className='project-content'>
                <ul>
                    <li>
                        <ImageCard
                            image={image_hdr_logo}
                            text='Real-time HDR at Mann Lab'
                            link='/experience/hdr'
                            width={
                                pageWidth < 350 ? '' + (pageWidth - 20) : '350'
                            }
                            height='200'
                            alt='Real-time HDR project'
                        />
                    </li>
                    <li>
                        <ImageCard
                            image={image_native_logo}
                            text='Inventory App written in React Native'
                            link='/experience/inventory-app'
                            width={
                                pageWidth < 350 ? '' + (pageWidth - 20) : '350'
                            }
                            height='200'
                            alt='Inventory App project'
                        />
                    </li>

                    <li>
                        <ImageCard
                            image={image_robo_logo}
                            text='RoboEdu Course Instructor, Developer'
                            link='/experience/roboedu'
                            width={
                                pageWidth < 350 ? '' + (pageWidth - 20) : '350'
                            }
                            height='200'
                            alt='RoboEdu experience'
                        />
                    </li>
                    <li>
                        <ImageCard
                            image={image_course_work_logo}
                            text='Course work and projects'
                            link='/experience/course'
                            width={
                                pageWidth < 350 ? '' + (pageWidth - 20) : '350'
                            }
                            height='200'
                            alt='Course work and projects'
                        />
                    </li>
                    <li>
                        <ImageCard
                            image={image_personal_project_logo}
                            text='Personal work and projects'
                            link='/experience/personal'
                            width={
                                pageWidth < 350 ? '' + (pageWidth - 20) : '350'
                            }
                            height='200'
                            alt='Personal work and projects'
                        />
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Projects;
