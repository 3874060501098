import React from 'react';
import { Link } from 'react-router-dom';
import './SideBar.css';

const SideBar = (props) => {
    let side_bar_class = 'side-bar';
    if (props.show) {
        side_bar_class += ' open';
    }
    const onClickHandler = () => {
        props.toggleSideBarHandler();
    };
    return (
        <nav className={side_bar_class}>
            <ul>
                <li onClick={onClickHandler}>
                    <Link to='/about'>About Me</Link>
                </li>
                <li onClick={onClickHandler}>
                    <Link to='/experience'>Projects</Link>
                </li>
                <li onClick={onClickHandler}>
                    <Link to='/contact'>Contact me!</Link>
                </li>
            </ul>
        </nav>
    );
};
export default SideBar;
