import React from 'react';
import './SideBarToggler.css';
const SideBarToggler = (props) => (
    <button className='toggle-button' onClick={props.clickHandler}>
        <div className='toggle-button_line' /> {/* hamburger menu */}
        <div className='toggle-button_line' />
        <div className='toggle-button_line' />
    </button>
);

export default SideBarToggler;
